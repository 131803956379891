import React, { useState } from "react"

import Form from "components/form"
// Components
import Container from "components/container"

// Libraries
import styled from "styled-components"

const StyledLetsTalk = styled.section`
  padding: 68px 0;
    h2 {
      padding-bottom: 1rem;
    }
}

`

const DownloadForm = () => {
  const [pwdChecked, setPasswordChecked] = useState(false) // is password checked?
  const [password, setPassword] = useState('') // is password checked?

  const handleChange = (event) => {
    setPassword(event.target.value);
  };
  
	const handleSubmit = (event) => {
		event.preventDefault();
    alert(password);

		if (password === 'Visby-123')
			setPasswordChecked(true);
	};

  return (
    <StyledLetsTalk>
      <Container data-aos="fade-up">
        {!pwdChecked ? (
          <div className="partner__form">
            <h2>Enter the password:</h2>
            <div className="password_form">
              <Form onSubmit={handleSubmit}>
                <div className="form__group">
                  <div className="form-element">
                    <input
                      id="password"
                      name="password"
                      type="text"
                      placeholder="Password*"
                      onChange={handleChange}
                      value={password}
                      required
                    />
                  </div>
              </div>
              
              <div className="form__submit">
                <button type="submit">
                  Check Password
                </button>
              </div>
              
              </Form>
            </div>
          </div>
          ) : (
            <div>
              <p><a href="https://drive.google.com/file/d/1pFbE-AlMfVih-wNB1VFbfcR6UQhJOI9S/view?usp=sharing" target="_blank" rel="noopener noreferrer">Quick Reference Guide</a></p>
              <p><a href="https://drive.google.com/file/d/1q6KsOeBx1U21ww_aNwnk_yy4FOGpr4fg/view?usp=sharing" target="_blank" rel="noopener noreferrer">Package Insert (Instructions for Use)</a></p>
              <p><a href="https://drive.google.com/file/d/1V7v2hoUzmZkiC8_0ANuv96fobafb96-s/view?usp=sharing" target="_blank" rel="noopener noreferrer">Specimen Collection Instructions 1</a></p>
              <p><a href="https://drive.google.com/file/d/1CgRS3AfjLcvFZJACO4V04DmVaL4TwSpe/view?usp=sharing" target="_blank" rel="noopener noreferrer">Specimen Collection Instructions 2</a></p>
              <p><a href="https://drive.google.com/file/d/1y3ZfkTxRTb7S5SP8HJOujl-CSyweVXUH/view?usp=sharing" target="_blank" rel="noopener noreferrer">Specimen Collection Instructions 3</a></p>
              <p><a href="https://drive.google.com/file/d/1Qev6u8QZGYL5FCWnNSzmHQT15qDuAVBa/view?usp=sharing" target="_blank" rel="noopener noreferrer">Fact Sheet for Patients</a></p>
              <p><a href="https://drive.google.com/file/d/1k0mZsNHq04BQFe0rFaKYV_mdUdj-KxN6/view?usp=sharing" target="_blank" rel="noopener noreferrer">Fact Sheet for Healthcare Providers</a></p>
            </div>
          )}
      </Container>
    </StyledLetsTalk>
  )
}

export default DownloadForm
